.student_profile {
    min-height: 100vh;
    background-color: #0e1621;

    @media (max-width:1500px){
        padding-top: 0 !important;
    }
}

.wrapper {
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1700px) {
        // width: 90%;
    }
    @media (max-width: 1500px) {
        width: 100%;
    }
}


.sidebar_content {
    overflow-x: hidden;
}


