.subquestion_style {
    & > p,
    span,
    b,
    strong,
    u,
    i,
    div {
        font-size: 16px !important;
        font-family: Rubik, Helvetica, Arial, serif !important;
        color: black;
    }
}

.sun-editor .se-btn {
    float: left;
    width: 34px;
    height: 34px;
    border: 0;
    border-radius: 4px;
    margin: 1px !important;
    padding: 2px 6px;
    font-size: 19px;
    line-height: 27px;
}

.sun-editor .se-resizing-bar .se-navigation {
    display: none;
}

.exam_info_data {
    user-select: none;
    & > p {
        span:nth-child(1) {
            display: block;
            font-size: 12px;
            color: #9ba3af;
            text-transform: uppercase;
            letter-spacing: 0.8px;
            text-shadow: 0 0 #9ba3af;

            svg {
                margin-right: 10px;
                color: #1d76f2;
            }
        }

        span:not(:nth-child(1)) {
            text-transform: uppercase;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            display: inline-block;
            margin-right: 10px;
            color: #5f6368;
            text-shadow: 0 0 #5f6368;
        }
    }
    & > p {
        font-size: 16px;
    }
}

.exam_password_box {
    background-color: #f2f6fe;
    padding: 30px 20px;
    border-radius: 6px;

    & > span {
        color: #1967d1;
        font-size: 14px;
    }
}
.exma_info_warning {
    background-color: #fcecea;
    padding: 30px 14px;
    border-radius: 6px;
    position: relative;
    & > p {
        color: #fc1313;
        font-size: 17px;
        margin-top: 8px;
    }

    & > a {
        position: absolute;
        bottom: 4px;
        right: 10px;
        font-size: 13px;
        text-transform: uppercase;
    }
}

.survey_box {
    .warning {
        background-color: #f2f6fe;
        color: #1d76f2;
        padding: 4px 10px;
        border-radius: 4px;
    }

    .list_item {
        margin-top: 8px;
    }
}

.waring_contract {
    color: #000;
    border: 1px solid #ff0000;
    border-radius: 6px;
    font-size: 16px;

    strong {
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    p {
        text-shadow: 0 0 #000;
        letter-spacing: 0.3px;
    }
}

.warning_exam_pass {
    background-color: #fcecea;
    color: #ff0000;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    user-select: none;

    & > strong {
        letter-spacing: 0.8px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
    }

    & > span {
        display: block;
        text-align: justify;
        letter-spacing: 0.3px;
        font-weight: 400;
    }
}

.exam_pass_info {
    background-color: white;
    margin-top: 10px;
    & > p {
        color: #a4a4a4;
        margin-bottom: 2px;
    }
    .date_box_exam_pass {
        text-align: justify;
        & > span {
            color: #717171;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 12px;
            margin-right: 4px;
        }
        & > b {
            color: #0e1621;
            font-weight: 300;
            text-shadow: 0 0 #0e1621;
        }
    }
}

.exam_sub_question {
    color: black;
    font-size: 16px !important;
}

.exam_error_page {
    border: 1px solid #f7f9fb;
    border-radius: 10px;
    padding: 10px 20px;
}

.student_answer_view_page {
    user-select: none;
    .body_question {
        margin-bottom: 20px;
    }

    .sub_question {
        border: 1px solid #edf1f4;
        border-radius: 6px;
        margin-top: 14px;
        padding: 10px;
        position: relative;

        .index_question {
            position: absolute;
            top: -12px;
            left: -12px;
            background-color: #f1f3f4;
            padding: 4px;
            font-weight: bold;
            color: #5f6368;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        table {
            width: 100%;
        }
        table,
        th,
        td {
            border: 1px solid #e1e1e1;
            padding: 2px 4px;
        }
    }
}

.exam_write_page {
    .table,
    td {
        border: 1px solid #e1e1e1 !important;
    }
    table,
    th,
    td {
        border: 1px solid #e1e1e1;
        padding: 2px 4px;
    }
}
