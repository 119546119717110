.student_language {
    padding: 0px 8px;
    border-radius: 4px;
    margin: 0 4px;
}

.student_language_img {
    width: 20px !important;
    height: 10px !important;
}
.student_language_text {
    font-size: 13px;
    font-weight: 400;
}

.student_tools_box {
    display: flex;
    align-items: center;

    svg {
        font-size: 18px !important;
        cursor: pointer;
    }
}
