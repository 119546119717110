.sidebar_btn {
    z-index: 10;
    border: none;
    padding: 0;
    background-color:transparent;


    & > svg {
        font-size: 22px;
        color: #5D48EC;
    }
}

