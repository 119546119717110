.student_header_language {
    height: 30px;
    border-radius: 4px;
    @media (min-width: 786px) and (max-width: 1320px) {
        height: 30px;
    }

    @media (min-width: 576px) and (max-width: 786px) {
        height: 28px;
    }
}



.header_language {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 2px 10px;
    height: 30px;
    width: 100px;

    @media (max-width: 700px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 6px;
        height: 28px;
        width: 70px;
        & > span {
            font-size: 12px;
        }
    }
}

.header_language_img {
    width: 18px;
    height: 10px;
}
